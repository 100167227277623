import{ defineStore }from"pinia";
import Toast from"@components/mobile/Toast.vue";
export const toastStore = defineStore("toast_store", {
	"id": "toast_store",
	"state": () => {
		return{
			"container": null,
			"message": ""
		}
	},
	"actions": {
		"open": function(message){
			this.container = null;
			this.message = "";
			window.setTimeout(() => {
				this.message = message;
				this.container = Toast;
			}, 0);
		},
		"close": function(){
			this.container = null;
		}
	}
});