<template>
<p ref="toast_message" data-toast_message>{{ toast_store.message }}</p>
</template>
<script setup>
import{ref, onMounted, onUnmounted}from"vue";
import{ toastStore }from"@store/mobile/toast";
const toast_store = toastStore();
const toast_message = ref( null );
let timer = null;
onMounted(()=>{
	toast_message.value.dataset["toast_message"] = "show";
	window.clearTimeout( timer );
    timer = window.setTimeout(()=>{
        toast_message.value.dataset["toast_message"] = "";
    }, 1500);
    toast_message.value.addEventListener("transitionend", ( event )=>{
        toast_store.container = null;
    });
});
onUnmounted(()=>{
	window.clearTimeout( timer );
});
</script>