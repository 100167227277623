import{ createRouter, createWebHistory}from"vue-router";
const routes = [
	{
		"path": "/",
		"name": "Home",
		"component": ()=>import("@/layouts/PcDefaultLayout.vue"),
		"children": [
			{
				"path": "/",
				"component": ()=>import("@views/pc/Index/Index.vue")
			},
			{
				"path": "/guide",
				"component": () => import("@views/pc/Guide/Index.vue"),
				"children": [
					{
						"path": "icon",
						"component": ()=>import("@views/pc/Guide/Element/Icon.vue")
					},
					{
						"path": "color",
						"component": ()=>import("@views/pc/Guide/Element/Color.vue")
					}
				]
			},
			{
				"path": "/play",
				"component": () => import("@views/pc/Play/Index.vue"),
				"children": [
					{
						"path": "exhibition",
						"component": ()=>import("@views/pc/Play/Exhibition.vue")
					}
				]
			},
			{
				"path": "/event",
				"component": () => import("@views/pc/Event/Index.vue"),
				"children": [
					{
						"path": "add",
						"component": ()=>import("@views/pc/Event/Add/Index.vue")
					},
					{
						"path": "edit",
						"children": [
							{
								"path": ":id",
								"component": ()=>import("@views/pc/Event/Add/Index.vue")
							}
						]
					}
				]
			}
		]
	}
];
export default createRouter({
	"mode": "history",
	"history": createWebHistory(),
	"routes": routes,
	scrollBehavior(to, from, savedPosition){
		// document.scrollingElement.scrollTop = 0;
	}
});