import{createApp}from"vue";
import{ createPinia }from"pinia";
import pc_router from"@/router/pc";
import mobile_router from"@/router/mobile";
import common_router from"@/router/common";
import nametag_router from"@/router/nametag";
import{assetsStaticUrlChange, addComma, onlyNumber}from"@utils/ofm_library";
import LoadScript from"vue-plugin-load-script";
import{ onClickOutside }from"@vueuse/core";
import{ createHead }from"@vueuse/head";
import{ popupStore }from"@store/popup";
import App from"@/App.vue";
import dayjs from"dayjs";
import"dayjs/locale/ko";
dayjs.locale("ko");
// const is_dev = import.meta.env.DEV;

const common_page_list = [
	"/account/login", "/account/login/guest", "/account/login/registered",
	"/account/find", "/account/find/email",
	"/account/join", "/account/join/terms", "/account/join/form", "/account/join/interest", "/account/join/complate"
];

createApp( App ).
use(createPinia()).
use(createHead()).
use(
	common_page_list.indexOf(window.location.pathname) > -1 ?
		common_router
	:
		window.location.hostname.indexOf("m.onoffmix") > -1 ?
			mobile_router
		:
			window.location.hostname.indexOf("nt.") > -1 ? nametag_router : pc_router
).
use( LoadScript ).
// use( VueCookies ).
directive("img", {
	created(el, binding, vnode, prevVnode){
		el.src = assetsStaticUrlChange(`/images${binding.value}`);
	}
}).
directive("comma", {
	mounted(el, binding) {
		if( el.nodeName === "INPUT" ){
			el.value = addComma( el.value ) + (binding.value ? binding.value : "");
		}else{
			el.textContent = addComma( el.textContent ) + (binding.value ? binding.value : "");	
		}
	}
}).
directive("inputFocusInRemoveComma", {
	mounted( el ){
		el.addEventListener("focus", ( event )=>{
			const target = event.target;
			target.value = onlyNumber(target.value);
		});
	}
}).
directive("inputFocusOutComma", {
	mounted(el, binding){
		el.addEventListener("blur", ( event )=>{
			const target = event.target;
			target.value = addComma(target.value) + (binding.value ? binding.value : "");
		});
	}
}).
directive("inputMaxNumFocusOut", {
	mounted(el, binding){
		el.addEventListener("blur", ( event )=>{
			const target = event.target;
			target.value = onlyNumber(target.value) > binding.value ? binding.value : onlyNumber(target.value)
		});
	}
}).
directive("commaInputFocusInOut", {
	mounted(el, binding){
		el.value = addComma(el.value) + (binding.value ? binding.value : "");
		el.addEventListener("focus", ( event )=>{
			const target = event.target;
			target.value = onlyNumber(target.value);
		});
		el.addEventListener("blur", ( event )=>{
			const target = event.target;
			const value_num = isNaN( Number(target.value) ) ? 0 : Number(target.value);
			const target_num = binding.value.indexOf("명") > -1 ?
				value_num > 9999 ? 9999 : value_num
			: binding.value.indexOf("원") > -1 ? value_num > 99999999 ? 99999999 : value_num : value_num;
			target.value = addComma(target_num) + (binding.value ? binding.value : "");
		});
		el.addEventListener("input", (event) => {
			const target = event.target;
			target.value = onlyNumber(target.value);
		});
	}
}).
directive("clickShowPopup", {
	mounted(el, binding) {
		const popup_store = popupStore();
		el.addEventListener("click", () => {
			const popupOpen = popup_store.open.bind( popup_store );
			popupOpen( binding.value );
		});
	}
}).
directive("clickClosePopup", {
	mounted(el) {
		const popup_store = popupStore();
		el.addEventListener("click", () => {
			const popupClose = popup_store.close.bind( popup_store );
			popupClose();
		});
	}
}).
directive("select", {
	mounted(el) {
		el.addEventListener("click", (event) => {
			const target = event.target;
			if(target.dataset["select"] !== undefined){
				target.dataset["select"] = target.dataset["select"] === "" ? "open" : "";
			}
		});
		onClickOutside(el, ( )=>{
			el.querySelector("[data-select]").dataset["select"] = "";
		});
	}
}).
mount(common_page_list.indexOf(window.location.pathname) > -1 ? "body" : "[data-entry_container]");