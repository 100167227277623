const env = import.meta.env;
const url_origin = window.location.origin;
const match = url_origin.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+(?:\.[^\/]+)?)(?:\/|$)/);
let subdomain = "";
let domain = "";
if( match ){
	const fullDomain = match[1];
	const parts = fullDomain.split(".");
	subdomain = parts.length > 2 ? parts[0] : null;
	domain = parts.slice(-2).join(".");
}

function assetsStaticUrlChange(name) {
	return env.MODE === "development" ?
		`/src/assets${name}`
	: `//static.${domain}/ofm/${env.MODE === "xyz_build" ? "xyz" : "com"}${name}`;
	/*
	return env.MODE === "development" ?
		new URL(`${window.location.origin}/assets${name}`)
	: env.MODE === "xyz_build" ?
		new URL(`${window.location.origin}/static_html/ofm/xyz/assets${name}`)
	: new URL(`https://static.onoffmix.com/ofm/assets${name}`);
	*/
}
function staticUrlChange(name) {
	return env.MODE === "development" ?
		`/src/assets${name}`
	: env.MODE === "xyz_build" ?
		`${window.location.origin}/static_html/ofm/xyz${name}`
	: `${window.location.origin}/static_html/ofm/com${name}`;
}
function defaultStaticUrlChange(name) {
	return env.MODE === "development" || env.MODE === "xyz_build" ? `/static_html${name}` : `https://static.onoffmix.com${name}`;
}
function addComma( num ){
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function isPhoneNumber( value ){
	return/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(value);
}
function isNoDashPhoneNumber( value ){
	return/^(011|016|017|018|019|010)[0-9]{7,8}$/.test(value);
}
function isTelNumber( value ){
	return/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(value);
}
function isOwnerName(value) {
	return/^\s+|\s+$/.test(value);
}
function passwordCheck(value) {
	return/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`\-=\\\[\];',\./~!@#\$%\^&\*\(\)_\+|\{\}:"<>\?])[A-Za-z\d`\-=\\\[\];',\./~!@#\$%\^&\*\(\)_\+|\{\}:"<>\?]/.test(value);
}
function phoneFormat( str ){
	str = str.replace(/ /g, "");
	return returnPhoneNumber(str);
}
function returnPhoneNumber( phone_num ){
	var phone_arr = [];
	if( /^1([5|6|8])([0-9]{6})$/.test(phone_num) ){
		phone_arr[0] = phone_num.slice(0, -4);
		phone_arr[1] = phone_num.substr(-4);
	}else if( /^01([0|1|6|7|8|9])([1-9][0-9]{6,7})$/.test(phone_num) ){
		phone_arr[0] = phone_num.slice(0, 3);
		phone_arr[1] = phone_num.slice(3, -4);
		phone_arr[2] = phone_num.substr(-4);
	}else if( /^0(2|31|32|33|41|42|43|44|51|52|53|54|55|61|62|63|64)[1-9][0-9]{6,7}$/.test(phone_num) ){
		if( phone_num.slice(0, 2) === "02" ){
			phone_arr[0] = phone_num.slice(0, 2);
			phone_arr[1] = phone_num.slice(2, -4);
		}else{
			phone_arr[0] = phone_num.slice(0, 3);
			phone_arr[1] = phone_num.slice(3, -4);
		}
		phone_arr[2] = phone_num.substr(-4);
	}else if( /^070[2-9][0-9]{7}$/.test(phone_num) ){
		phone_arr[0] = phone_num.slice(0, 3);
		phone_arr[1] = phone_num.slice(3, -4);
		phone_arr[2] = phone_num.substr(-4);
	}else if( /^(030|050|060|080)[0-9]{7,8}$/.test(phone_num) ){
		phone_arr[0] = phone_num.slice(0, 3);
		phone_arr[1] = phone_num.slice(3, -4);
		phone_arr[2] = phone_num.substr(-4);
	}else{
		phone_arr[0] = phone_num.slice(0, 4);
		phone_num = phone_num.replace(phone_arr[0], "");
		if( phone_num.length  ){
			phone_arr[1] = phone_num.substr(-4);
		}
	}
	return phone_arr.join("-");
}
function phoneDashFormat( str ){
	str = str.replace(/ /g, "");
	str = phoneFormat( str );
	return phoneDashInsert( str );
}
function phoneDashInsert( value ){
	return value.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, "");
}
function onlyNumber(value) {
	return Number( String(value).replace(/[^0-9]/g, "") );
}
function onlyStringNumber(value) {
	return value.replace(/[^0-9]/g, "");
}
function onlyNumberInputSetting( event ){
	const target = event.target;
	return target.value = target.value.replace(/[^0-9]/g, "");
}
function formDataSetting( object ){
	const return_form_data = new FormData();
	for(var key of Object.keys(object)){
		if( Object.prototype.toString.call(object[key]) === "[object Array]" ){
			object[key].forEach(( data )=>{
				return_form_data.append((key + "[]"), data); 
			});
		}else{
			return_form_data.append(key, object[key]);
		}
	}
	return return_form_data;
}
function formQueryString( form ){
	const form_data = new FormData(form);
	const params = new URLSearchParams();
	for(const[name, value]of form_data){
		params.append(name, value);
	}
	return params.toString();
}
function parseQueryString(queryString) {
	const params = new URLSearchParams(queryString);
	const result = {};
	for(const[name, value]of params) {
		if(result.hasOwnProperty(name)) {
			if(Array.isArray(result[name])) {
				result[name].push(value);
			}else{
			result[name] = [result[name], value];
			}
		}else{
			result[name] = value;
		}
	}
	return result;
}
function triggerEvent(el, type){
	const event = new Event(type, {"bubbles": true, "cancelable": true});
	el.dispatchEvent(event);
}
function delay(){
	return new Promise(resolve => setTimeout(resolve, 0))
}
function isElementInViewport(el){
	const rect = el.getBoundingClientRect();
	return(
		rect.top >= 0 && rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}
function emojiRemove( str ){
	const unified_emoji_ranges = ["[\ud800-\ud8bf][\udc00-\udfff]", "[\ud8c0-\udabf][\udc00-\udfff]", "[\udac0-\udb7f][\udc00-\udfff]"];
	const reg = new RegExp(unified_emoji_ranges.join("|"), "g");
	return str.replace(reg, "");
}
export{ 
	assetsStaticUrlChange,
	staticUrlChange,
	defaultStaticUrlChange,
	addComma,
	isPhoneNumber,
	isNoDashPhoneNumber,
	isTelNumber,
	isOwnerName,
	passwordCheck,
	phoneFormat,
	returnPhoneNumber,
	phoneDashFormat,
	phoneDashInsert,
	onlyNumber,
	onlyStringNumber,
	onlyNumberInputSetting,
	formDataSetting,
	formQueryString,
	parseQueryString,
	triggerEvent,
	delay,
	isElementInViewport,
	emojiRemove
}