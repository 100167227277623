import{ createRouter, createWebHistory}from"vue-router";
const routes = [
	{
		"path": "/",
		"name": "Home",
		"children": [
			{
				"path": ":id",
				"component": () => import("@views/pc/NameTag/Index.vue"),
				"beforeEnter": (to, from, next) => {
					// if(window.location.origin.indexOf("nt.onoffmix") === -1){
					// 	window.location.href = window.location.origin.replace("nt.", "");
					// }
					next();
				}
			}
		]
	}
];
export default createRouter({
	"mode": "history",
	"history": createWebHistory(),
	"routes": routes,
	scrollBehavior(to, from, savedPosition){
		// document.scrollingElement.scrollTop = 0;
	}
});