import{ ref }from"vue";
import{ defineStore }from"pinia";
import PopupContainer from"@components/popup/PopupContainer.vue";
import CheckUserAgent from"@utils/check_user_agent";

export const commonStore = defineStore("common_store", () => {
	const user_agent = new CheckUserAgent();
	// -------------------------------------------------------------------------------------------------
	const device_type = ref(user_agent.device_type);
	const platform_type = ref(user_agent.platform_type);
	const is_app = ref(user_agent.is_app);
	const checkUserAgentChange = () => {
		const current_device_type = user_agent.device_type;
		const current_platform_type = user_agent.platform_type;
		if(device_type.value !== current_device_type) {
			device_type.value = current_device_type;
		}
		if(platform_type.value !== current_platform_type) {
			platform_type.value = current_platform_type;
		}
	}
	window.addEventListener("resize", checkUserAgentChange);
	// -------------------------------------------------------------------------------------------------
	const limit_below_versions_check = user_agent.limitBelowVersionsCheck;
	// -------------------------------------------------------------------------------------------------
	const screenTypeSetting = () => {
		if(window.innerWidth >= 1280) {
			return"pc";
		}else if(window.innerWidth > 599 && window.innerWidth < 1280) {
			return"tablet";
		}else{
			return"mobile";
		}
	}
	const mobile_media = matchMedia("(max-width: 599px)");
	const tablet_media = matchMedia("(max-width: 1279px)");
	const pc_media = matchMedia("(min-width: 1280px)");
	const screen_type = ref( screenTypeSetting() );
	mobile_media.addListener(() => {
		screen_type.value = screenTypeSetting();
	});
	tablet_media.addListener(() => {
		screen_type.value = screenTypeSetting();
	});
	pc_media.addListener(() => {
		screen_type.value = screenTypeSetting();
	});
	// -------------------------------------------------------------------------------------------------
	const market = user_agent.market;
	const lastVer = user_agent.lastVer;
	const appInfo = user_agent.appInfo;
	// -------------------------------------------------------------------------------------------------
	const popup = {
		"option": {
			"position": "center"
		},
		"data": null,
		"container": null,
		"component": null,
		"open": function() {
			this.container = PopupContainer;
		},
		"close": function(){
			if(this.container) {
				document.querySelector(`[${this.container.setup()().dynamicProps[0]}]`).classList.remove("on");
			}
		}
	};
	// -------------------------------------------------------------------------------------------------
	const page_show = ref(false);
	const loading_show = ref(false);
	// -------------------------------------------------------------------------------------------------
	return{
		device_type,
		is_app,
		screen_type,
		platform_type,
		market,
		lastVer,
		appInfo,
		popup,
		page_show,
		loading_show,
		limit_below_versions_check
	};
});