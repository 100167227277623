<template>
<div :data-popup_container="popup_store.option.position">
	<div ref="popup" data-popup>
		<component :is="popup_store.component" />
		<button v-if="popup_store.option.close_btn" v-clickClosePopup type="button" data-popup_close_btn>팝업닫기</button>
	</div>
</div>
</template>
<script setup>
import{ ref }from"vue";
import{ onClickOutside }from"@vueuse/core";
import{ popupStore }from"@store/popup";
const popup_store = popupStore();

const popup = ref(null);
onClickOutside(popup, ( event ) => {
	popup_store.close();
});
</script>