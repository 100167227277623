import{ commonStore }from"@store/common.js";
import{ createRouter, createWebHistory }from"vue-router";
const routes = [{
	"path": "/",
	"component": ()=>import("@/layouts/CommonContentLayout.vue"),
	"children": [
		{
			"path": "account",
			"component": () => import("@common_views/Account/Index.vue"),
			"children": [
				{
					"path": "login",
					"component": () => import("@common_views/Account/Login/Index.vue"),
					"meta": {
						"pc_mobile_single": true,
						"pc_no_header": true
					},
					"children": [
						{
							"path": "guest",
							"component": () => import("@common_views/Account/Login/Index.vue")
						},
						{
							"path": "registered",
							"component": () => import("@common_views/Account/Login/Index.vue")
						}
					]
				},
				{
					"path": "find",
					"component": () => import("@common_views/Account/Find/Index.vue"),
					"meta": {
						"pc_mobile_single": true,
						"pc_no_header": true
					},
					"children": [
						{
							"path": "email",
							"component": () => import("@common_views/Account/Find/Index.vue")
						}
					]
				},
				{
					"path": "join",
					"component": () => import("@common_views/Account/Join/Index.vue"),
					"meta": {
						"pc_mobile_single": true,
						"pc_no_header": true
					}
				}
			]
		}
	]
}];

const router = createRouter({
	"mode": "history",
	"history": createWebHistory(),
	"routes": routes,
	scrollBehavior(to, from, savedPosition) {
		if( !to.meta.no_scroll_top ){
			document.scrollingElement.scrollTop = 0;
		}
	}
});
router.beforeEach((to, from, next) => {
	const common_store = commonStore();
	common_store.popup.close();
	next();
});
router.afterEach((to, from) => {
	/*
	const common_store = commonStore();
	setTimeout(() => {
		common_store.page_show = true;
	}, 100);
	*/
});
export default router;