import{createRouter, createWebHistory, createWebHashHistory}from"vue-router";
import{ popupStore }from"@store/popup";
import{ toastStore }from"@store/mobile/toast";
const routes = [
	{
		"path": "/",
		"name": "Home",
		"component": ()=>import("@/layouts/MobileDefaultLayout.vue"),
		"children": [
			{
				"path": "/",
				"component": ()=>import("@views/mobile/Index/Index.vue")
			},
			{
				"path": "/play",
				"component": () => import("@views/mobile/Play/Index.vue"),
				"children": [
					{
						"path": "main",
						"meta": {
							"header": {
								"title": "온오프믹스 플레이",
								"search": true,
								"share": false
							},
							"footer": false
						},
						"component": () => import("@views/mobile/Play/Main.vue")
					},
					{
						"path": ":id",
						"component": () => import("@views/mobile/Play/Detail.vue"),
						"meta": {
							"header": {
								"title": "상품상세",
								"search": true,
								"share": true
							},
							"footer": false
						}
					},
					{
						"path": "exhibition",
						"meta": {
							"header": {
								"title": "온오프믹스 플레이",
								"search": true,
								"share": false
							},
							"footer": false
						},
						"component": () => import("@views/mobile/Play/Exhibition.vue")
					}
				]
			},
			{
				"path": "/rsvp",
				"children": [
					{
						"path": "play",
						"component": () => import("@views/mobile/Play/Index.vue"),
						"children": [
							{
								"path": "attend",
								"children": [
									{
										"path": ":id",
										"component": () => import("@views/mobile/Play/Attend.vue"),
										"props": true,
										"meta": {
											"header": {
												"title": "결제하기",
												"search": false,
												"share": false
											},
											"footer": false
										}
									}
								]
							}
						]
					}
				]
			}
		]
	}
];
const router = createRouter({
	"mode": "history",
	"history": createWebHistory(),
	// "history": createWebHashHistory(),
	"routes": routes,
	scrollBehavior(to, from, savedPosition){
		if( !to.meta.no_scroll_top ) {
			document.scrollingElement.scrollTop = 0;
		}
		if( savedPosition ){
			return savedPosition;
		}else{
			return{ top: 0 };
		}
	}
});
router.beforeEach((to, from, next) => {
	const popup_store = popupStore();
	const toast_store = toastStore();

	popup_store.close();
	toast_store.close();
	next();
	/* useHead({"link": {"rel": "canonical", "href": to.meta.canonical ? to.meta.canonical : ""}});
		window.setTimeout(()=>{
		next();
	}, 1); */
});
export default router;