<template>
<div ref="popup_container" :data-popup_container="common_store.popup.option.position">
	<component :is="common_store.popup.component" ref="popup" data-popup />
</div>
</template>
<script setup>
import{ref, onMounted, watch}from"vue";
import{ onClickOutside }from"@vueuse/core";
import{ commonStore }from"@store/common.js";
const common_store = commonStore();

const popup_container = ref(null);
const popup = ref(null);
onMounted(() => {
	onClickOutside(popup.value, (event) => {
		common_store.popup.close();
	});
	setTimeout(() => {
		popup_container.value.classList.add("on");
	}, 0);
	popup_container.value.addEventListener("transitionend", (event) => {
		if( !popup_container.value.classList.contains("on") ){
			common_store.popup.component = null;
			common_store.popup.data = null;
			common_store.popup.container = null;
			common_store.popup.option = {
				"position": "center"
			}
		}
	})
});
</script>
<style lang="sass" scoped>
[data-popup_container]
	display: flex
	width: 100%
	height: 100%
	position: fixed
	left: 0
	top: 0
	background-color: rgba(0, 0, 0, 0.6)
	z-index: 100
	[data-popup]
		transform: translateY(100%)
		transition: transform ease-out .2s
		:deep([data-popup_content])
			overflow-y: scroll
			-webkit-overflow-scrolling: touch
	&.on
		[data-popup]
			transform: translateY(0%)
[data-popup_container="full"]
	justify-content: center
	background-color: #fff
	height: 100%
[data-popup_container="center"]
	justify-content: center
	align-items: center
	[data-popup]
		width: auto
[data-popup_container="bottom"]
	align-items: flex-end
	justify-content: center
	[data-popup]
		border-bottom-left-radius: 0
		border-bottom-right-radius: 0
[data-popup_container="left"]
	justify-content: flex-start
[data-popup_container="right"]
	justify-content: flex-end
[data-pc_mobile_single="true"]
	[data-popup_container]
		[data-popup]
			max-width: 600px
</style>