import{ defineStore }from"pinia";
import Popup from"@components/Popup.vue";
export const popupStore = defineStore("popup_store", {
	"id": "popup_store",
	"state": () => {
		return{
			"option": {
				"position": "center",
				"title": false,
				"close_btn": true
			},
			"data": null,
			"container": null,
			"component": null
		}
	},
	"actions": {
		"open": function( popup_obj ){
			this.option = popup_obj.option;
			this.data = popup_obj.data;
			this.component = popup_obj.component;
			this.container = Popup;
		},
		"close": function(){
			this.container = null;
			this.component = null;
			this.data = null;
			this.option = {
				"position": "center",
				"title": false,
				"close_btn": true
			}
		}
	}
});